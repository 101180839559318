import { Component } from '@angular/core';
import { StatsigService } from '@statsig/angular-bindings';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

  constructor(private _ngxZendeskWebwidgetService: NgxZendeskWebwidgetService, private statsigService: StatsigService) {
  }
  title = 'LaraHealth';
}
