"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutoCapture = exports.runStatsigAutoCapture = exports.StatsigAutoCapturePlugin = void 0;
const client_core_1 = require("@statsig/client-core");
const AutoCaptureEvent_1 = require("./AutoCaptureEvent");
const Utils_1 = require("./Utils");
const payloadUtils_1 = require("./payloadUtils");
const PAGE_INACTIVE_TIMEOUT = 600000;
const AUTO_EVENT_MAPPING = {
  submit: AutoCaptureEvent_1.AutoCaptureEventName.FORM_SUBMIT,
  click: AutoCaptureEvent_1.AutoCaptureEventName.CLICK
};
class StatsigAutoCapturePlugin {
  constructor(_options) {
    this._options = _options;
    this.__plugin = 'auto-capture';
  }
  bind(client) {
    runStatsigAutoCapture(client, this._options);
  }
}
exports.StatsigAutoCapturePlugin = StatsigAutoCapturePlugin;
function runStatsigAutoCapture(client, options) {
  return new AutoCapture(client, options);
}
exports.runStatsigAutoCapture = runStatsigAutoCapture;
class AutoCapture {
  constructor(_client, options) {
    var _a, _b, _c;
    this._client = _client;
    this._startTime = Date.now();
    this._deepestScroll = 0;
    this._disabledEvents = {};
    this._previousLoggedPageViewUrl = null;
    this._hasLoggedPageViewEnd = false;
    this._inactiveTimer = null;
    const {
      sdkKey,
      errorBoundary,
      values
    } = _client.getContext();
    this._disabledEvents = (_b = (_a = values === null || values === void 0 ? void 0 : values.auto_capture_settings) === null || _a === void 0 ? void 0 : _a.disabled_events) !== null && _b !== void 0 ? _b : {};
    this._errorBoundary = errorBoundary;
    this._errorBoundary.wrap(this);
    this._eventFilterFunc = options === null || options === void 0 ? void 0 : options.eventFilterFunc;
    const doc = (0, client_core_1._getDocumentSafe)();
    if (!(0, client_core_1._isServerEnv)()) {
      __STATSIG__ = (0, client_core_1._getStatsigGlobal)();
      const instances = (_c = __STATSIG__.acInstances) !== null && _c !== void 0 ? _c : {};
      instances[sdkKey] = this;
      __STATSIG__.acInstances = instances;
    }
    if ((doc === null || doc === void 0 ? void 0 : doc.readyState) === 'loading') {
      doc.addEventListener('DOMContentLoaded', () => this._initialize());
      return;
    }
    this._initialize();
  }
  _addEventHandlers() {
    const win = (0, client_core_1._getWindowSafe)();
    const doc = (0, client_core_1._getDocumentSafe)();
    if (!win || !doc) {
      return;
    }
    const eventHandler = (event, userAction = true) => {
      this._autoLogEvent(event || win.event);
      if (userAction) {
        this._bumpInactiveTimer();
      }
    };
    (0, Utils_1._registerEventHandler)(doc, 'click', e => eventHandler(e));
    (0, Utils_1._registerEventHandler)(doc, 'submit', e => eventHandler(e));
    (0, Utils_1._registerEventHandler)(win, 'error', e => eventHandler(e, false));
    (0, Utils_1._registerEventHandler)(win, 'pagehide', () => this._tryLogPageViewEnd());
    (0, Utils_1._registerEventHandler)(win, 'beforeunload', () => this._tryLogPageViewEnd());
    (0, Utils_1._registerEventHandler)(win, 'scroll', () => this._scrollEventHandler());
  }
  _addPageViewTracking() {
    const win = (0, client_core_1._getWindowSafe)();
    const doc = (0, client_core_1._getDocumentSafe)();
    if (!win || !doc) {
      return;
    }
    (0, Utils_1._registerEventHandler)(win, 'popstate', () => this._tryLogPageView());
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, [state, unused, url]) => {
        target.apply(thisArg, [state, unused, url]);
        this._tryLogPageView();
      }
    });
    this._tryLogPageView();
  }
  _autoLogEvent(event) {
    var _a;
    const eventType = (_a = event.type) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    if (eventType === 'error' && event instanceof ErrorEvent) {
      this._logError(event);
      return;
    }
    const target = (0, Utils_1._getTargetNode)(event);
    if (!target) {
      return;
    }
    if (!(0, Utils_1._shouldLogEvent)(event, target)) {
      return;
    }
    const eventName = AUTO_EVENT_MAPPING[eventType];
    if (!eventName) {
      return;
    }
    const {
      value,
      metadata
    } = (0, Utils_1._gatherEventData)(target);
    this._enqueueAutoCapture(eventName, value, metadata);
  }
  _bumpInactiveTimer() {
    const win = (0, client_core_1._getWindowSafe)();
    if (!win) {
      return;
    }
    if (this._inactiveTimer) {
      clearTimeout(this._inactiveTimer);
    }
    this._inactiveTimer = win.setTimeout(() => {
      this._tryLogPageViewEnd(true);
    }, PAGE_INACTIVE_TIMEOUT);
  }
  _initialize() {
    this._addEventHandlers();
    this._addPageViewTracking();
    this._logSessionStart();
    this._logPerformance();
  }
  _logError(event) {
    const error = (event === null || event === void 0 ? void 0 : event.error) || {};
    let errorStr = error;
    if (typeof error === 'object') {
      try {
        errorStr = JSON.stringify(error);
      } catch (e) {
        errorStr = typeof (error === null || error === void 0 ? void 0 : error.toString) === 'function' ? error.toString() : 'Unknown Error';
      }
    }
    this._enqueueAutoCapture(AutoCaptureEvent_1.AutoCaptureEventName.ERROR, event.message, {
      message: event.message,
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      error_str: errorStr
    });
  }
  _logSessionStart() {
    const session = this._getSessionFromClient();
    try {
      if (!this._isNewSession(session)) {
        return;
      }
      this._enqueueAutoCapture(AutoCaptureEvent_1.AutoCaptureEventName.SESSION_START, (0, Utils_1._getSanitizedPageUrl)(), {
        sessionID: session.data.sessionID
      }, {
        flushImmediately: true
      });
    } catch (err) {
      this._errorBoundary.logError('AC::logSession', err);
    }
  }
  _tryLogPageView() {
    const url = (0, Utils_1._getSafeUrl)();
    const last = this._previousLoggedPageViewUrl;
    if (last && url.href === last.href) {
      return;
    }
    this._previousLoggedPageViewUrl = url;
    this._hasLoggedPageViewEnd = false;
    const payload = (0, payloadUtils_1._gatherPageViewPayload)(url);
    this._enqueueAutoCapture(AutoCaptureEvent_1.AutoCaptureEventName.PAGE_VIEW, (0, Utils_1._getSanitizedPageUrl)(), payload, {
      flushImmediately: true,
      addNewSessionMetadata: true
    });
    this._bumpInactiveTimer();
  }
  _tryLogPageViewEnd(dueToInactivity = false) {
    if (this._hasLoggedPageViewEnd) {
      return;
    }
    this._hasLoggedPageViewEnd = true;
    this._enqueueAutoCapture(AutoCaptureEvent_1.AutoCaptureEventName.PAGE_VIEW_END, (0, Utils_1._getSanitizedPageUrl)(), {
      scrollDepth: this._deepestScroll,
      pageViewLength: Date.now() - this._startTime,
      dueToInactivity
    }, {
      flushImmediately: true
    });
  }
  _logPerformance() {
    const win = (0, client_core_1._getWindowSafe)();
    if (typeof (win === null || win === void 0 ? void 0 : win.performance) === 'undefined' || typeof win.performance.getEntriesByType !== 'function' || typeof win.performance.getEntriesByName !== 'function') {
      return;
    }
    setTimeout(() => {
      const metadata = {};
      const navEntries = win.performance.getEntriesByType('navigation');
      if (navEntries && navEntries.length > 0 && navEntries[0] instanceof PerformanceNavigationTiming) {
        const nav = navEntries[0];
        metadata['load_time_ms'] = nav.duration;
        metadata['dom_interactive_time_ms'] = nav.domInteractive - nav.startTime;
        metadata['redirect_count'] = nav.redirectCount;
        metadata['transfer_bytes'] = nav.transferSize;
      }
      const fpEntries = win.performance.getEntriesByName('first-contentful-paint');
      if (fpEntries && fpEntries.length > 0 && fpEntries[0] instanceof PerformancePaintTiming) {
        metadata['first_contentful_paint_time_ms'] = fpEntries[0].startTime;
      }
      const networkInfo = (0, Utils_1._getSafeNetworkInformation)();
      if (networkInfo) {
        metadata['effective_connection_type'] = networkInfo.effectiveType;
        metadata['rtt_ms'] = networkInfo.rtt;
        metadata['downlink_kbps'] = networkInfo.downlink;
        metadata['save_data'] = networkInfo.saveData;
      }
      this._enqueueAutoCapture(AutoCaptureEvent_1.AutoCaptureEventName.PERFORMANCE, (0, Utils_1._getSanitizedPageUrl)(), metadata);
    }, 1);
  }
  _enqueueAutoCapture(eventName, value, metadata, options) {
    var _a, _b, _c;
    const subname = eventName.slice('auto_capture::'.length);
    if (this._disabledEvents[eventName] || this._disabledEvents[subname]) {
      return;
    }
    const session = this._getSessionFromClient();
    try {
      const logMetadata = Object.assign({
        sessionID: session.data.sessionID,
        page_url: (_c = (_b = (_a = (0, client_core_1._getWindowSafe)()) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.href) !== null && _c !== void 0 ? _c : ''
      }, metadata);
      if (options === null || options === void 0 ? void 0 : options.addNewSessionMetadata) {
        logMetadata['isNewSession'] = String(this._isNewSession(session));
      }
      const event = {
        eventName,
        value,
        metadata: logMetadata
      };
      if (this._eventFilterFunc && !this._eventFilterFunc(event)) {
        return;
      }
      this._client.logEvent(event);
      if (options === null || options === void 0 ? void 0 : options.flushImmediately) {
        this._client.flush().catch(e => {
          client_core_1.Log.error(e);
        });
      }
    } catch (err) {
      this._errorBoundary.logError('AC::enqueue', err);
    }
  }
  _scrollEventHandler() {
    var _a, _b, _c, _d;
    const scrollHeight = (_b = (_a = (0, client_core_1._getDocumentSafe)()) === null || _a === void 0 ? void 0 : _a.body.scrollHeight) !== null && _b !== void 0 ? _b : 1;
    const win = (0, client_core_1._getWindowSafe)();
    const scrollY = (_c = win === null || win === void 0 ? void 0 : win.scrollY) !== null && _c !== void 0 ? _c : 1;
    const innerHeight = (_d = win === null || win === void 0 ? void 0 : win.innerHeight) !== null && _d !== void 0 ? _d : 1;
    this._deepestScroll = Math.max(this._deepestScroll, Math.min(100, Math.round((scrollY + innerHeight) / scrollHeight * 100)));
    this._bumpInactiveTimer();
  }
  _isNewSession(session) {
    // within the last second
    return Math.abs(session.data.startTime - Date.now()) < 1000;
  }
  _getSessionFromClient() {
    return this._client.getContext().session;
  }
}
exports.AutoCapture = AutoCapture;