"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatsigAutoCapturePlugin = exports.runStatsigAutoCapture = exports.AutoCapture = exports.AutoCaptureEventName = void 0;
const AutoCapture_1 = require("./AutoCapture");
Object.defineProperty(exports, "AutoCapture", {
  enumerable: true,
  get: function () {
    return AutoCapture_1.AutoCapture;
  }
});
Object.defineProperty(exports, "StatsigAutoCapturePlugin", {
  enumerable: true,
  get: function () {
    return AutoCapture_1.StatsigAutoCapturePlugin;
  }
});
Object.defineProperty(exports, "runStatsigAutoCapture", {
  enumerable: true,
  get: function () {
    return AutoCapture_1.runStatsigAutoCapture;
  }
});
var AutoCaptureEvent_1 = require("./AutoCaptureEvent");
Object.defineProperty(exports, "AutoCaptureEventName", {
  enumerable: true,
  get: function () {
    return AutoCaptureEvent_1.AutoCaptureEventName;
  }
});
__STATSIG__ = Object.assign(Object.assign({}, __STATSIG__ !== null && __STATSIG__ !== void 0 ? __STATSIG__ : {}), {
  AutoCapture: AutoCapture_1.AutoCapture,
  runStatsigAutoCapture: AutoCapture_1.runStatsigAutoCapture,
  StatsigAutoCapturePlugin: AutoCapture_1.StatsigAutoCapturePlugin
});
exports.default = __STATSIG__;