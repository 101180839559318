"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._getSafeNetworkInformation = exports._registerEventHandler = exports._getSanitizedPageUrl = exports._getSafeUrl = exports._shouldLogEvent = exports._getTargetNode = exports._gatherEventData = exports._gatherDatasetProperties = void 0;
const client_core_1 = require("@statsig/client-core");
function _gatherDatasetProperties(el) {
  const dataset = {};
  if (!el) {
    return dataset;
  }
  const attr = el === null || el === void 0 ? void 0 : el.dataset;
  if (!attr) {
    return dataset;
  }
  for (const key in attr) {
    dataset[`data-${key}`] = attr[key] || '';
  }
  return dataset;
}
exports._gatherDatasetProperties = _gatherDatasetProperties;
function _gatherEventData(target) {
  var _a;
  const tagName = target.tagName.toLowerCase();
  const metadata = {};
  const value = (0, client_core_1._getCurrentPageUrlSafe)() || '';
  metadata['tagName'] = tagName;
  if (tagName === 'form') {
    metadata['action'] = target.getAttribute('action');
    metadata['method'] = (_a = target.getAttribute('method')) !== null && _a !== void 0 ? _a : 'GET';
    metadata['formName'] = target.getAttribute('name');
    metadata['formId'] = target.getAttribute('id');
  }
  if (['input', 'select', 'textarea'].includes(tagName) && target.getAttribute('type') !== 'password') {
    metadata['content'] = target.value;
    metadata['inputName'] = target.getAttribute('name');
  }
  const anchor = _getAnchorNodeInHierarchy(target);
  if (anchor) {
    metadata['href'] = anchor.getAttribute('href');
  }
  if (tagName === 'button' || anchor) {
    metadata['content'] = (target.textContent || '').trim();
    const dataset = _gatherDatasetProperties(anchor || target);
    Object.assign(metadata, dataset);
  }
  return {
    value,
    metadata
  };
}
exports._gatherEventData = _gatherEventData;
function _getTargetNode(e) {
  if (!e) {
    return null;
  }
  let target = e.target || e.srcElement;
  if (!target || !(target instanceof Element)) {
    return null;
  }
  if (target.nodeType === 3) {
    target = target.parentNode || null;
  }
  return target;
}
exports._getTargetNode = _getTargetNode;
function _shouldLogEvent(e, el) {
  if (!e || !el || el.nodeType !== 1) {
    return false;
  }
  const tagName = el.tagName.toLowerCase();
  const eventType = e.type.toLowerCase();
  switch (tagName) {
    case 'html':
      return false;
    case 'form':
      return eventType === 'submit';
    case 'input':
    case 'select':
    case 'textarea':
      return ['change'].includes(eventType);
    default:
      if (eventType === 'click') {
        if (tagName === 'button') {
          return true;
        }
        const anchor = _getAnchorNodeInHierarchy(el);
        if (anchor) {
          return true;
        }
      }
      return false;
  }
}
exports._shouldLogEvent = _shouldLogEvent;
function _getSafeUrl() {
  var _a, _b, _c;
  const href = (_c = (_b = (_a = (0, client_core_1._getWindowSafe)()) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.href) !== null && _c !== void 0 ? _c : '';
  let url;
  try {
    url = new URL(href);
  } catch (e) {
    url = new URL('error:');
  }
  return url;
}
exports._getSafeUrl = _getSafeUrl;
function _getSanitizedPageUrl() {
  return (0, client_core_1._getCurrentPageUrlSafe)() || '';
}
exports._getSanitizedPageUrl = _getSanitizedPageUrl;
function _registerEventHandler(element, eventType, handler) {
  if (!element || !element.addEventListener) {
    return;
  }
  element.addEventListener(eventType, handler, true);
}
exports._registerEventHandler = _registerEventHandler;
function _getSafeNetworkInformation() {
  const win = (0, client_core_1._getWindowSafe)();
  if (!win || !win.navigator) {
    return null;
  }
  const connection = win.navigator.connection;
  if (!connection) {
    return null;
  }
  return connection;
}
exports._getSafeNetworkInformation = _getSafeNetworkInformation;
function _getAnchorNodeInHierarchy(node) {
  if (!node) {
    return null;
  }
  let parent = node;
  while (parent) {
    const parentTagName = parent.tagName.toLowerCase();
    if (['body', 'document'].includes(parentTagName)) {
      return null;
    }
    if (parent.tagName.toLowerCase() === 'a') {
      return parent;
    }
    parent = parent.parentElement;
  }
  return null;
}