"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._gatherPageViewPayload = void 0;
const client_core_1 = require("@statsig/client-core");
function _gatherPageViewPayload(url) {
  var _a, _b;
  const safeDoc = (0, client_core_1._getDocumentSafe)();
  const safeWnd = (0, client_core_1._getWindowSafe)();
  if (!safeDoc || !safeWnd) {
    return {};
  }
  const navigator = safeWnd === null || safeWnd === void 0 ? void 0 : safeWnd.navigator;
  const referrer = (safeDoc === null || safeDoc === void 0 ? void 0 : safeDoc.referrer) || '';
  let refUrl = new URL('empty:');
  if (referrer) {
    try {
      refUrl = new URL(referrer || 'empty:');
    } catch (e) {
      /* empty */
    }
  }
  const searchInfo = getSearchInfo(refUrl);
  const campaignParams = getCampaignParams(url);
  const queryParams = {};
  url.searchParams.forEach((v, k) => {
    queryParams[k] = v;
  });
  return Object.assign(Object.assign(Object.assign(Object.assign({}, searchInfo), campaignParams), queryParams), {
    title: (safeDoc === null || safeDoc === void 0 ? void 0 : safeDoc.title) || '',
    locale: (navigator === null || navigator === void 0 ? void 0 : navigator.language) || 'unknown',
    hostname: url.hostname || 'unknown',
    pathname: url.pathname || 'unknown',
    referrer,
    screen_width: ((_a = safeWnd === null || safeWnd === void 0 ? void 0 : safeWnd.screen) === null || _a === void 0 ? void 0 : _a.width) || 'unknown',
    screen_height: ((_b = safeWnd === null || safeWnd === void 0 ? void 0 : safeWnd.screen) === null || _b === void 0 ? void 0 : _b.height) || 'unknown',
    viewport_width: (safeWnd === null || safeWnd === void 0 ? void 0 : safeWnd.innerWidth) || 'unknown',
    viewport_height: (safeWnd === null || safeWnd === void 0 ? void 0 : safeWnd.innerHeight) || 'unknown'
  });
}
exports._gatherPageViewPayload = _gatherPageViewPayload;
function getCampaignParams(url) {
  const urlParams = url.searchParams;
  const campaignParams = {};
  const commonUtms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'gclid',
  // Google
  'gclsrc',
  // Google
  'dclid',
  // DoubleClick
  'fbclid',
  // Facebook
  'msclkid',
  // Bing
  'mc_eid',
  // Mailchimp
  'mc_cid',
  // Mailchimp
  'twclid',
  // Twitter
  'li_fat_id',
  // LinkedIn
  'igshid',
  // Instagram
  'utm_id',
  // Hubspot
  'ttc',
  // TikTok
  'ttclid',
  // TikTok
  'ttc_id' // TikTok
  ];
  commonUtms.forEach(p => {
    const val = urlParams.get(p);
    if (val) {
      campaignParams[p] = val;
    }
  });
  return campaignParams;
}
function getSearchEngine(refUrl) {
  const host = refUrl.hostname;
  const engine = ['google', 'bing', 'yahoo', 'duckduckgo', 'baidu'].find(e => host.includes(e + '.'));
  return engine || '';
}
function getSearchInfo(refUrl) {
  const searchEngine = getSearchEngine(refUrl);
  const searchQuery = refUrl.searchParams.get(searchEngine === 'yahoo' ? 'p' : 'q') || '';
  return {
    searchEngine,
    searchQuery
  };
}